import React from "react";
import Card from "~/components/styled/card";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "~/components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { formatPrice } from "~/helpers/currency-formatter";
import { ArrowRightIcon } from "@heroicons/react/outline";
import PageHeading from "~/components/styled/page-heading";

const UpcomingRides = () => {
	return (
		<Layout>
			<GatsbySeo
				title="Upcoming Active Adventures in India and Asia"
				description="Yearly calendar of active tours in India and Asia."
				// languageAlternates={[
				// 	{
				// 		hrefLang: "en-IN",
				// 		href: "https://www.artofbicycletrips.in/upcoming-rides",
				// 	},
				// 	{
				// 		hrefLang: "en",
				// 		href: "https://www.artofbicycletrips.com/private",
				// 	},
				// ]}
			/>
			<section className="">
				<div className="my-20">
					<h1 className="font-bold">Upcoming Adventures</h1>
					<h2 className="font-light text-primary max-w-3xl mb-14">
						Most tours depart with a minimum of 6 people traveling
					</h2>
				</div>

				<p className="mb-10 prose">
					Connect with the adventurers; who enjoy the camaraderie of exploring the
					world; over one of our upcoming departures.
				</p>

				{/* <h3 className=" mb-4">Get Notified of Our Upcoming Departures</h3>
				<a href="https://www.instagram.com/artofbicycleclub/" target="_blank">
					<p className="leading-loose hover:text-primary md:text-xl max-w-2xl">
						‣ Follow us on Instagram
					</p>
				</a>
				<a href="https://t.me/+bAmODh53O3I0NjM1" target="_blank">
					<p className="leading-loose hover:text-primary md:text-xl max-w-2xl">
						‣ Join our Telegram Channel
					</p>
				</a> */}

				{/* <a href="https://chat.whatsapp.com/BmatbfMpjQ42m4JkaLgJCP" target="_blank">
					<p className="leading-loose hover:text-primary md:text-xl max-w-2xl m-auto">
						‣ Join our WhatsApp group Or
					</p>
				</a>
				<a href="http://eepurl.com/hnf28r" target="_blank">
					<p className="leading-loose hover:text-primary md:text-xl max-w-2xl m-auto">
						‣ Subscribe to our newsletter
					</p>
				</a> */}

				{/* <h2 className="mt-20 mb-6">August 2022</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/central-goa-bike-tour">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/goa-bike-tour-5.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
							
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize truncate hover:text-primary">
										Enchanting Goa Cycling Adventure
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Goa</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {38900 && formatPrice(38900)} | 10 Aug to 14 Aug
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">5 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">boutique inns</span>
									</div>
								</div>
								<div>
									<span className="text-sm text-gray-500 lowercase">moderate</span>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/three-states-loop-from-bengaluru">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/kerala-bike-tour-18.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize truncate hover:text-primary">
										Three States Loop from Bengaluru
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											South India
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {28800 && formatPrice(28800)} | 11 Aug to 14 Aug
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">4 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">boutique inns</span>
									</div>
								</div>
								<div>
									<span className="text-sm text-gray-500 lowercase">moderate</span>
								</div>
							</div>
						</Link>
					</Card>
				</div>
				<h2 className="mt-20 mb-6">September 2022</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/central-goa-bike-tour">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/goa-bike-tour-5.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize truncate hover:text-primary">
										Enchanting Goa Cycling Adventure
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Goa</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {38900 && formatPrice(38900)} | 13 Sep to 17 Sep
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">5 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">boutique inns</span>
									</div>
								</div>
								<div>
									<span className="text-sm text-gray-500 lowercase">moderate</span>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/sikkim-bike-tour">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/sikkim-bike-tour-6.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize truncate hover:text-primary">
										Sikkim Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Sikkim</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {49900 && formatPrice(49900)} | 24 Sep - 1 Oct
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">boutique inns</span>
									</div>
								</div>
								<div>
									<span className="text-sm text-gray-500 lowercase">challenging</span>
								</div>
							</div>
						</Link>
					</Card>
				</div>
				<h2 className="mt-20 mb-6">October 2022</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/enthralling-meghalaya-cycling-tour">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/meghalaya_mawphunlur_cycling_fast.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize truncate hover:text-primary">
										Enthralling Meghalaya Cycling Adventure
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Meghalaya
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {49900 && formatPrice(49900)} | 01 Oct to 08 Oct
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">boutique inns</span>
									</div>
								</div>
								<div>
									<span className="text-sm text-gray-500 lowercase">moderate</span>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/central-goa-bike-tour">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/goa-bike-tour-5.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize truncate hover:text-primary">
										Enchanting Goa Cycling Adventure
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Goa</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {38900 && formatPrice(38900)} | 11 Oct to 15 Oct
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">5 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">boutique inns</span>
									</div>
								</div>
								<div>
									<span className="text-sm text-gray-500 lowercase">moderate</span>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/three-states-loop-from-bengaluru">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/kerala-bike-tour-27.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize truncate hover:text-primary">
										Kerala Backwaters in Style
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Kerala</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {28800 && formatPrice(28800)} | 19 Oct to 22 Oct
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">4 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">boutique inns</span>
									</div>
								</div>
								<div>
									<span className="text-sm text-gray-500 lowercase">easy</span>
								</div>
							</div>
						</Link>
					</Card>
				</div>
				<h2 className="mt-20 mb-6">November 2022</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/east-arunachal-cycling-tour">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/arunachal-cycling-4.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize truncate hover:text-primary">
										East Arunachal Cycling Adventure
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Arunachal
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {49900 && formatPrice(49900)} | 05 Nov to 11 Nov
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">7 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">boutique inns</span>
									</div>
								</div>
								<div>
									<span className="text-sm text-gray-500 lowercase">
										easy to moderate
									</span>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/central-goa-bike-tour">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/goa-bike-tour-5.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize truncate hover:text-primary">
										Enchanting Goa Cycling Adventure
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Goa</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {38900 && formatPrice(38900)} | 15 Nov to 19 Nov
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">5 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">boutique inns</span>
									</div>
								</div>
								<div>
									<span className="text-sm text-gray-500 lowercase">moderate</span>
								</div>
							</div>
						</Link>
					</Card>
				</div> */}
				<h2 className="mt-20 mb-6">April 2022</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					{/* <Card>
						<Link to="/tours/central-goa-bike-tour">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/goa-bike-tour-5.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize truncate hover:text-primary">
										Enchanting Goa Cycling Adventure
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Goa</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {34900 && formatPrice(34900)} | 07 to 10 Dec
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">4 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">boutique inns</span>
									</div>
								</div>
								<div>
									<span className="text-sm text-gray-500 lowercase">moderate</span>
								</div>
							</div>
						</Link>
					</Card> */}
					<Card>
						<Link to="/tours/enthralling-meghalaya-cycling-tour">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/meghalaya-seven-sister-falls.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize truncate hover:text-primary">
										Enthralling Meghalaya Cycling Adventure
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Meghalaya
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {49900 && formatPrice(49900)} | 7 to 14 Apr
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">boutique inns</span>
									</div>
								</div>
								<div>
									<span className="text-sm text-gray-500 lowercase">moderate</span>
								</div>
							</div>
						</Link>
					</Card>
					{/* <Card>
						<Link to="/tours/saigon-to-angkor-bike-tour">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-17.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize truncate hover:text-primary">
										Vietnam to Cambodia Cycling Adventure
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Vietnam and Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {121500 && formatPrice(121500)} | 24 Dec to 01 Jan
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">boutique inns</span>
									</div>
								</div>
								<div>
									<span className="text-sm text-gray-500 lowercase">easy</span>
								</div>
							</div>
						</Link>
					</Card> */}
				</div>
			</section>
		</Layout>
	);
};

export default UpcomingRides;
